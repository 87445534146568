import React from 'react'
import {
    Section,
    Container,
    Row,
    Grid
} from '../Grid'
import {connect} from 'react-redux'
import {fetchClients} from '../../actions'
import {BASE_URL} from '../../path'

class ClientSection extends React.Component {

    componentDidMount(){
        this.props.fetchClients()
    }

    renderList(){
        return this.props.clients.map((item, index) => {
            if(item.image){
                return (
                    <figure>
                        <a href={item.link} target="_blank">
                            <img key={index} src={`${BASE_URL}${item.image}`} alt={item.alt} width="170" />
                        </a>
                    </figure>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    render() {
        
        return(
            <Section className="opt_double-grid-section opt_clients-section" >
                <Container>
                    <Row>
                        <Grid col="12">
                            {/* <h3 className="white">Our Friends</h3> */}
                            <h2 className="themeTurquoise">Some of Our Clients</h2>
                        </Grid>
                        <Grid col="12">
                            <div className="opt_client-img">
                                {this.renderList()}
                            </div>
                        </Grid>
                    </Row>
                </Container>
            </Section>
        )
    }
}

const mapStateToProps = state => {
    return {
        clients: state.clientsState
    }
}

export default connect(mapStateToProps, {
    fetchClients
})(ClientSection)