import React from 'react'
import axios from 'axios'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import {BASE_URL} from '../../path'
// import Recaptcha from 'react-recaptcha';

import loader from '../../assets/img/ajax-loader.gif'

const initialState = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  message: '',
  nameError: '',
  surnameError: '',
  emailError: '',
  phoneError: '',
  messageError: '',
  isVerified: false,
  submitted: false
};

class GetInTouch extends React.Component {

    state = initialState

    handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';
        this.setState({
            [event.target.name]: isCheckbox
            ? event.target.checked
            : event.target.value
        });
     }

    validate = () => {
        let nameError = '';
        let surnameError = '';
        let emailError = '';
        let phoneError = '';
        let messageError = '';

        if (!this.state.name) {
            nameError = 'You must enter your name';
        }

        if (!this.state.surname) {
            surnameError = 'You must enter your surname';
        }

        if (!this.state.email) {
             emailError = 'You must enter your e-mail address';
        } else if (!this.state.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
            emailError = 'You must enter a valid e-mail address';
        }

        if (!this.state.phone) {
             phoneError = 'You must enter your phone number';
        }

        if (!this.state.message) {
            messageError = 'You must enter a message';
        }

        if ( nameError || surnameError || emailError || phoneError || messageError) {
            this.setState({ nameError, surnameError, emailError, phoneError, messageError });
            return false;
        }

        return true;
    }

    // recaptchaLoaded = () => {
    //     console.log('capcha successfully loaded');
    // }

    // verifyCallback = response => {
    //     if (response) {
    //         this.setState({
    //             isVerified: true
    //         })
    //         console.log('capcha successfully verified');
    //     }
    // }

    handleSubmit = event => {
        event.preventDefault();        
        const isValid = this.validate();
        if(isValid){

            // const verified = this.state.isVerified;            
            // if (verified) {

                this.setState({
                    submitted: true
                })
                axios({
                    method: "POST",
                    url: `${BASE_URL}/api/email/contact`,
                    data:  this.state
                }).then((response)=>{
                    console.log(response)
                    if (response.status === 200){
                        this.onSubmitSuccess();
                        this.setState(initialState);
                    } else {
                        this.onSubmitFailed();
                        this.setState(initialState);
                    }
                })
                
            // } else {
            //     alert('Please confirm you are human by clicking on the "I\'m not a robot" checkbox.')
            // }
            
        }         
    }

    onSubmitSuccess = () => {
        window.location.href="/thank-you"
    }
    onSubmitFailed= () => {
        window.location.href="/we-are-sorry"
    }

    render(){
        return(
            <Section className="opt_form-section">
                <Container className="opt_small">
                    {/* <h3>Send us a message</h3> */}
                    <h2>Get In Touch</h2>
                    <p>To discuss a project, please complete the form below and one of our Account Directors will be in touch.</p>
                    <form onSubmit={this.handleSubmit}>
                        <Row>
                                                   
                            <Grid col="6">
                                <input className="opt_form-controll" name="name" type="text" placeholder="Name" value={this.state.name} onChange={this.handleChange} />
                                <div className="opt_form-error">{this.state.nameError}</div>
                            </Grid>
                            <Grid col="6">
                                <input className="opt_form-controll" name="surname" type="text" placeholder="Surname" value={this.state.surname} onChange={this.handleChange} />
                                <div className="opt_form-error">{this.state.surnameError}</div>
                            </Grid>
                            <Grid col="6">
                                <input className="opt_form-controll" name="email" type="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} />
                                <div className="opt_form-error">{this.state.emailError}</div>
                            </Grid>
                            <Grid col="6">
                                <input className="opt_form-controll" name="phone" type="tel" placeholder="Phone Number" value={this.state.phone} onChange={this.handleChange} />
                                <div className="opt_form-error">{this.state.phoneError}</div>
                            </Grid>
                            <Grid>
                                <textarea className="opt_form-controll" name="message" placeholder="Message" value={this.state.message} onChange={this.handleChange} ></textarea>
                                <div className="opt_form-error">{this.state.messageError}</div>
                            </Grid>
                            <Grid className="opt_recapcha">
                                {/* <Recaptcha
                                    sitekey="6LfndL4aAAAAANgit5p6BCTUSb7dUMD_bc1UQD3N"
                                    render="explicit"
                                    onloadCallback={this.recaptchaLoaded}
                                    verifyCallback={this.verifyCallback}
                                    theme="dark"
                                /> */}
                            </Grid>
                            <Grid className="opt_display-flex opt_justifyContent-center">
                                {
                                    this.state.submitted && 
                                    <div className="opt_submitting-msg">
                                        <img src={loader} alt="" />
                                        <p>Sending message. Please wait...</p>
                                    </div>
                                }
                                {
                                    !this.state.submitted && 
                                    <input className="opt_form-submit" type="submit" value="Submit" />
                                    
                                }
                                
                            </Grid>                        
                        
                        </Row>
                    </form>
                </Container>
            </Section>
        )
    }
}

export default GetInTouch