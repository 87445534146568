import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import {
    Section,
    Container,
    Row,
    Grid
} from'../../components/Grid'
import Button from '../../components/Buttons/Button'

class ThankYou extends React.Component {
    renderPageContent() {
        return(
            <React.Fragment>

                <Section className="opt_thank-you-section">
                    <Container>
                        <Row>
                            <Grid col="12">
                                <div className="opt_thank-you-content">
                                    <h1 className="themeTurquoise">Thank You</h1>
                                    <p className="white">Thank you for your enquiry. A consultant will be in touch with you shortly</p>
                                    <Button text="Close" />
                                </div>
                            </Grid>
                        </Row>
                    </Container>
                </Section>
                
            </React.Fragment>

        )
    }

    render() {
        
        return(
            <React.Fragment>
                <Helmet>
                    <title>Optimal Online | Thank You Page</title>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <Nav>{this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

export default ThankYou