import React from 'react'
import {
    Section,
    Container,
    Row,
    Grid
} from '../Grid'
import Clearfix from '../Helpers/Clearfix'
import bgImg from '../../assets/img/who-we-are-02@2x.png'

class WhoWeAreSection extends React.Component {
    render() {
        const style = {
            backgroundImage: `url(${bgImg})`
        }
        return(
            <Section className="opt_double-grid-section opt_who-we-are-section opt_swopped" >
                <Container>
                    <Row>
                        <Grid col="6">
                            <div className="opt_bg-img opt_who-we-are-img" style={style}></div>
                        </Grid>
                        <Grid col="6">
                            <div className="opt_banner-text">
                                <div className="opt_content">
                                    {/* <h3 className="white">{this.props.subHeading}</h3> */}
                                    <h2 className="themeTurquoise">{this.props.heading}</h2>
                                    <div className="opt_who-we-are-list"><span>01</span><Clearfix /> {this.props.listText1}</div>
                                    <div className="opt_who-we-are-list"><span>02</span><Clearfix /> {this.props.listText2}</div>
                                    <div className="opt_who-we-are-list"><span>03</span><Clearfix /> {this.props.listText3}</div>
                                </div>
                            </div>
                        </Grid>
                    </Row>
                </Container>
            </Section>
        )
    }
}
export default WhoWeAreSection