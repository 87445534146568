import React from 'react'
import closeIcon from '../../assets/img/close-icon.svg'
import NavLink from './NavLink'
import Button from '../Buttons/Button'
import bgImg from '../../assets/img/Optimal-Icon-01.svg'
import {
    Container,
    Row,
    Grid
} from '../Grid'

class Sidebar extends React.Component {

    render() {
        const style = {
            backgroundImage: `url(${bgImg})`
        }
        const close = this.props.close
        return(
            <div className="opt_sidebar" style={style} >
                <Container className="valign-center_halign-left">
                <Row>
                    <Grid col="6"> 
                         <ul className="opt_ul_big">
                            <h6>MENU</h6>
                            <NavLink href="/" text="Home" />
                            <NavLink href="/work" text="Projects" />
                            <NavLink href="/services" text="What We Do" />                  
                            <NavLink href="/about" text="Who We Are" />
                            <NavLink href="/contact" text="Get In Touch" />
                        </ul>
                    </Grid>
                    <Grid col="6">
                        <div className="opt_side_bar_discuss_project">
                            <span>Let's Get Cracking </span><span className="vl"></span> <Button text="Discuss a Project" url="/contact" />
                        </div>
                    </Grid>
                </Row>
                </Container>
                <button className="opt_btn-menu" onClick={close}>
                    <img src={closeIcon} alt="" width="30" height="30" />
                </button>
                
            </div>
        )
    }
}

export default Sidebar;