import React from 'react'
import {Link} from 'react-router-dom'
import Sidebar from './Sidebar'
import Footer from '../Footer/Footer'
import logo from '../../assets/img/Optimal-Online-Logo.svg'
import bgImg from '../../assets/img/Optimal-Icon-01.svg'
import discussProjectIcon from '../../assets/img/discuss-a-project-button-2.svg'
import menuIcon from '../../assets/img/Menu-Button.svg'
import ScrollToTop from '../Helpers/ScrollToTop'
import logoInverted from '../../assets/img/optimal-online-logo-dark.svg'

class Nav extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            sidebar: '',
            headerTransformation: false
        }
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleHeaderTransformation)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleHeaderTransformation)
    }

    handleHeaderTransformation = () => {
        if(window.scrollY > 100){
            if(!this.state.headerTransformation){
                this.setState({
                    headerTransformation: true
                })
            }
            
        } else {
            if(this.state.headerTransformation){
                this.setState({
                    headerTransformation: false
                })
            }
        }
    }

    onMenuOpen = () => {
        this.setState({
            sidebar: 'open'
        })
    }

    onMenuClosed = () => {
        this.setState({
            sidebar: ''
        })
    }

    renderScrollToTop = () => {
        if(!this.props.noScrollToTop){
            return <ScrollToTop />
        } else {
            if (!window.location.hash){
                return <ScrollToTop />
            }
        }
    }

    render() {
        const style = {
            backgroundImage: `url(${bgImg})`
        }
        return(
            <>
                {this.renderScrollToTop()}
                <div className={`main ${this.state.sidebar}`}>

                    <Sidebar close={this.onMenuClosed} open={this.onMenuOpen} />
                    
                    <div className="page_content">
                        <header className={this.state.headerTransformation ? 'opt_transformed' : ''}>
                            <div className="opt_header-top-left-column">
                                <Link to='/'>
                                    <img className="opt_logo-top" src={this.props.logoInverted ? logoInverted : logo} alt="Optimal Online" />
                                    <img className="opt_logo-scrolled" src={logo} alt="Optimal Online" />
                                </Link>
                            </div>
                            

                            <div className="opt_header-top-right-column">
                                <button className="opt_btn-menu opt_discuss-project-button">
                                    <Link to="/contact"><span>Discuss a Project</span><img src={discussProjectIcon} alt="" /></Link>
                                </button>
                                <button className="opt_btn-menu" onClick={this.onMenuOpen}>
                                    <img src={menuIcon} alt="" />
                                </button>
                            </div>
                            
                        </header>
                        
                        <div id="top_of_page" className={`${this.state.isProject ? 'project_page_border' : 'page_border'}`}  style={style}>
                            <div className="page_overlay">
                                {this.props.children}
                            </div>

                            <Footer />
                        </div>

                    </div>
                </div>

            </>
        )
    }
}

export default Nav;