import React from 'react'
import {Redirect} from 'react-router-dom'

class ServicesRedirect extends React.Component{
    render(){
        
        return <Redirect to="/services" />
    }
}

export default ServicesRedirect