import React from 'react'

export class Section extends React.Component {
	render(){
		return (
			<section 
				className={`opt_section ${this.props.className ? this.props.className : ''}`}
				id={this.props.id ? this.props.id : ''} 
				style={this.props.style ? this.props.style : null}
			>
				{this.props.children}
			</section>
		)
	}
}

export class Container extends React.Component {
	render(){
		return (
			<div className={`opt_container ${this.props.className ? this.props.className : ''}`}>
				{this.props.children}
			</div>
		)
	}
}

export class Row extends React.Component {
	render(){
		return (
			<div className={`opt_row ${this.props.className ? this.props.className : ''}`}>
				{this.props.children}
			</div>
		)
	}
}

export class Grid extends React.Component {
	render(){
		return (
			<div className={`opt_grid opt_grid-${this.props.col ? this.props.col : '12'} ${this.props.className ? this.props.className : ''}`}>
				{this.props.children}
			</div>
		)
	}
}