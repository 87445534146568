import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import TopHeading from '../../components/Content/TopHeading'
import {
    Section,
    Container,
    Row,
    Grid
} from'../../components/Grid'
import image from '../../assets/img/kyledavies@2x.jpg'

class Kyledavies extends React.Component {
    renderPageContent() {
        return(
            <React.Fragment>
                <TopHeading
                    heading="Meet The Founder - Kyle Davies"
                    pageHeading="1"
                    image={image}
                >
                    <p>After acquiring his marketing degree at the Academy of Marketing & Communication in Frankfurt, Germany, Kyle Davies’ work ethic, ideas and ways of doing the extraordinary has seen him stay on top of the marketing game for over 20 years. </p>
                    
                    <p>Kyle gained extensive knowledge in the fields of integrated marketing - above and below the line, direct and digital. He worked extensively on complex strategies for various liquor, automotive, financial and tobacco accounts which resulted in him working with top agencies such as Wunderman, Ogilvy, iKineo and Mabua. </p>
                    
                    <p>He founded Optimal Online in November 2015 and based its vision on developing customised digital services that not only work but most importantly deliver on their defined objectives. </p>
                    
                    <p>Optimal Online has already acquired numerous blue chip clients and has succeeded in building long-lasting relationships and solid foundations with the likes of Graham Beck, Philip Morris International, Vinimark & Accountability. </p>
                    
                    <p>Kyle’s expertise has led him to become one of South Africa’s forerunners in Customer Relationship Marketing, Social Media, Digital and Mobile Marketing. </p>
                    
                    <p>If you asked Kyle why he does what he does, he would simply tell you that relationships are what really matter to people, and thus brands as well.</p>
                </TopHeading>
            </React.Fragment>
        )
    }

    render() {
        
        return(
            <React.Fragment>
                <Helmet>
                    <title>Optimal Online | Kyle Davies</title>
                    <meta name="description" content="After acquiring his marketing degree at the Academy of Marketing & Communication in Frankfurt, Germany, Kyle Davies’ work ethic, ideas and ways of doing the extraordinary has seen him stay on top of the marketing game for over 20 years." />
                </Helmet>
                <Nav>{this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

export default Kyledavies