import React from 'react'
import Button from '../Buttons/Button'

class BannerText extends React.Component {

    render() {
        
        return(            
            <div className="opt_banner-text">
                <div className={`opt_content ${this.props.props.scroll ? 'opt_scroll' : ''}`}>
                    <h2 className="themeTurquoise"
                        style={{
                            color: this.props.props.textColor ? this.props.props.textColor : '#FFFFFF'
                    }}>{this.props.props.heading}</h2>
                    <div className="white" 
                        style={{
                            color: this.props.props.textColor ? this.props.props.textColor : '#FFFFFF'
                        }}
                        dangerouslySetInnerHTML={{ __html: this.props.props.text }}></div>
                    {
                        this.props.props.buttonText && 
                        <Button 
                            text={this.props.props.buttonText} 
                            url={this.props.props.buttonLink ? this.props.props.buttonLink : '/'}
                        />
                    }
                </div>
            </div>
        )
    }
}

export default BannerText