import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import {
    Section,
    Container,
    Row,
    Grid
} from'../../components/Grid'
import DoubleGridSection from '../../components/Content/DoubleGridSection'
import FeaturedProjectSection from '../../components/Content/FeaturedProjectSection'
import FeaturedProjectGrid from '../../components/Content/FeaturedProjectGrid'
import Button from '../../components/Buttons/Button'
import {connect} from 'react-redux'
import {fetchProjectDetails} from '../../actions'
import {fetchProjectsMore} from '../../actions'
import {BASE_URL} from '../../path'
import PageNotFound from '../PageNotFound/PageNotFound'

class Project extends React.Component {

    componentDidMount(){
        this.props.fetchProjectDetails(this.props.match.params.searchName);
        this.props.fetchProjectsMore(this.props.match.params.searchName);
    }

    componentDidUpdate(prevProps){    
        if(this.props.match.params.searchName !== prevProps.match.params.searchName){
            this.props.fetchProjectDetails(this.props.match.params.searchName);
            this.props.fetchProjectsMore(this.props.match.params.searchName);
        }   
    }

    renderMainServiceImage(){
        if(this.props.project.client){
            return (
                <a href={this.props.project.client.link} target="_blank">
                    <figure>
                        <img className="opt_dropshadow" src={`${BASE_URL}${this.props.project.main_service_img}`} alt={this.props.project.title} />
                    </figure>
                </a>
            )
        } else {
            return (
                <figure>
                    <img className="opt_dropshadow" src={`${BASE_URL}${this.props.project.main_service_img}`} alt={this.props.project.title} />
                </figure>
            )
        }
    }

    renderList(){
        return this.props.projects.map((item, index) => {
            if(item.title){
                return (
                    <FeaturedProjectGrid 
                        key={index}
                        bgImg={`${BASE_URL}${item.featured_img}`}
                        heading={item.title}
                        subHeading={`${item.main_service_heading} | ${item.service_heading}`}
                        buttonLink={`/work/${item.search_name}`}
                        textColor={this.props.project.text_color}
                    />
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderPageContent() {
        const style = {
            backgroundImage: `url(${BASE_URL}${this.props.project.banner_img})`
        }
        const backgroundColor = {
            backgroundColor: `${this.props.project.color}`
        }
        const style2 = {
            backgroundImage: `url(${BASE_URL}${this.props.project.challenge_img})`
        }
        return(
            <React.Fragment>

                <Helmet>
                    <title>Optimal Online | {this.props.project.title ? this.props.project.title : 'Project'}</title>
                    <meta name="description" content={this.props.project.meta_description ? this.props.project.meta_description : ''} />
                </Helmet>

                <Section className="opt_project-page-heading opt_swopped" style={style}>
                    <Container>
                        <Row>
                            <Grid col="4">
                                <figure>
                                    <img src={`${BASE_URL}${this.props.project.logo}`} alt={this.props.project.title} />
                                </figure>                          
                            </Grid>
                            <Grid col="8">
                                <h1 className="white" style={{
                                    color: this.props.project.text_color
                                }}>{this.props.project.title}</h1>
                                <h3 className="white" style={{
                                    color: this.props.project.text_color
                                }}>{`${this.props.project.main_service_heading === null ? '' : this.props.project.main_service_heading} ${this.props.project.service_heading && this.props.project.main_service_heading ? ' | ' : ''} ${this.props.project.service_heading === null ? '' : this.props.project.service_heading}`}</h3>                               
                            </Grid>
                        </Row>
                    </Container>
                </Section>

                {
                    this.props.project.client_description &&
                        <div className="opt_project-page-overlay " style={backgroundColor}>
                            <DoubleGridSection style={backgroundColor}
                                img={`${BASE_URL}${this.props.project.client_img}`}
                                alt={this.props.project.title}
                                heading="About The Client"
                                text={this.props.project.client_description}
                                order="1"
                                projectPage="1"
                                scroll={true}
                                textColor={this.props.project.text_color}
                            />    
                        </div>
                }
                
                {
                    this.props.project.challenge_description && 
                        <Section className="opt_project-page-center opt_text-left-aligned-on-mobile" style={style2}>
                            <Container className="opt_small">
                                <Row>
                                    <Grid col="12">
                                        <h2 className="white" style={{
                                            color: this.props.project.text_color
                                        }}>The Challenge</h2>
                                        <p className="white" style={{
                                                color: this.props.project.text_color
                                            }} dangerouslySetInnerHTML={{ __html: this.props.project.challenge_description }}></p>
                                    </Grid>
                                </Row>
                            </Container>
                        </Section>
                }

                {
                    this.props.project.service_heading && 
                        <div className="opt_project-page-overlay opt_text-left-aligned-on-mobile" style={backgroundColor}>
                            <DoubleGridSection
                                img={`${BASE_URL}${this.props.project.service_img}`}
                                alt={this.props.project.title}
                                heading={this.props.project.service_heading}
                                text={this.props.project.service_description}
                                order="0"
                                projectPage="1"
                                scroll={true}
                                textColor={this.props.project.text_color}
                            />
                        </div>
                }

            
                {
                    this.props.project.main_service_heading && 
                        <Section className="opt_project-page-center opt_text-left-aligned-on-mobile" style={backgroundColor}>
                            <Container className="opt_small">
                                <Row>
                                    <Grid col="12">
                                        <h2 className="opt_center" style={{
                                            color: this.props.project.text_color
                                        }}>{this.props.project.main_service_heading}</h2>
                                        {
                                            this.props.project.main_service_description && 
                                            <p className="white" style={{
                                                color: this.props.project.text_color
                                            }} dangerouslySetInnerHTML={{ __html: this.props.project.main_service_description }}></p>
                                        }
                                    </Grid>
                                </Row>
                            </Container>
                        </Section>
                }
            

                {
                    this.props.project.main_service_img &&
                        <Section className="opt_project-page-center" style={backgroundColor}>
                            <Container className="opt_small">
                                <Row>
                                    <Grid col="12">
                                        {this.renderMainServiceImage()}
                                        {/* <figure>
                                            <img src={`${BASE_URL}${this.props.project.main_service_img}`} alt={this.props.project.title} />
                                        </figure> */}
                                    </Grid>
                                </Row>
                            </Container>
                        </Section>
                }

            
                <Section className="opt_project-page-center" style={backgroundColor}>
                    <Container className="opt_small">
                        <Row>
                            <Grid col="12">
                                <h2 className="white opt_see-more-heading" style={{
                                    color: this.props.project.text_color
                                }}>See more work</h2>
                                
                            </Grid>
                        </Row>
                    </Container>
                </Section>
            

            
                <div className="opt_project-page-overlay" style={backgroundColor}>
                    <FeaturedProjectSection projectPage="1" >
                        {this.props.projects && this.renderList()}
                        <Grid col="12" className="opt_featured-project-button">
                            <Button text="View Projects" url="/work" />
                        </Grid>
                    </FeaturedProjectSection>
                </div>
                
                
            </React.Fragment>

        )
    }

    setLogo(){

        let output = null
        switch(this.props.match.params.searchName) {
        case 'vinimark':
            output = true
            break;
        case 'allure':
            output = true
            break;
        default:
            // code block
        }
        return output
    }

    render() {

        if(this.props.project){
            if(this.props.project.name == "Error"){
                console.log('error')
                return <PageNotFound/>
            }
        }
        
        return(
            <React.Fragment>
                <Nav logoInverted={this.setLogo()}>{this.props.project && this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

// export default Project

const mapStateToProps = (state, ownProps) => {
    return {
        project: state.projectDetailsState[ownProps.match.params.searchName],
        projects: state.projectsMoreState[ownProps.match.params.searchName],
    }
}

export default connect(mapStateToProps, {
    fetchProjectDetails,
    fetchProjectsMore
})(Project)