import React from 'react'
import Button from '../Buttons/Button'
import OnScrollAnimation from  '../Animations/OnScrollAnimation.js'

class TopBannerText extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            subHeading: this.props.props.subHeading,
            heading: this.props.props.heading,
            text: this.props.props.text,
            buttonText: this.props.props.buttonText ? this.props.props.buttonText : null,
            buttonLink: this.props.props.buttonLink ? this.props.props.buttonLink : null
        }
    }
    render() {
        return(
            
            <div className="opt_top-banner-text">
                <div className="opt_content">
                    {/* <h3 className="white">{this.state.subHeading}</h3> */}
                    <h1 className="themeTurquoise">{this.state.heading}</h1>
                    <p className="white">{this.state.text}</p>
                    {
                        this.state.buttonText && 
                        <Button 
                            text={this.state.buttonText} 
                            url={this.state.buttonLink}
                        />
                    }
                </div>
            </div>
            
        )
    }
}

export default TopBannerText;