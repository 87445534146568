import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import {
    Section,
    Container,
    Row,
    Grid
} from'../../components/Grid'
import Button from '../../components/Buttons/Button'

class PageNotFound extends React.Component{
    renderPageContent() {
        return(
            <React.Fragment>

                <Section className="opt_thank-you-section">
                    <Container>
                        <Row>
                            <Grid col="12">
                                <div className="opt_thank-you-content">
                                    <h1 className="themeTurquoise">404 Page Not Found</h1>
                                    <p className="white">Sorry we couldn't find what you were looking for.</p>
                                    <Button text="Close" />
                                </div>
                            </Grid>
                        </Row>
                    </Container>
                </Section>
                
            </React.Fragment>

        )
    }

    render() {
        
        return(
            <React.Fragment>
                <Helmet>
                    <title>Optimal Online | 404</title>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <Nav>{this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

export default PageNotFound