import React from 'react'
import {
    Section,
    Container,
    Row,
    Grid
} from '../Grid'
import TopBannerText from '../Content/TopBannerText'

class TopBanner extends React.Component {
    render() {
        return(
            <Section className="opt_top-banner opt_swopped">
                <Container>
                    <Row>
                        <Grid col="6">
                            <figure>
                                <img src={this.props.img} alt="" />
                            </figure>
                        </Grid>
                        <Grid col="6">
                            <TopBannerText 
                                props={this.props}
                            />
                        </Grid>
                    </Row>
                </Container>
            </Section>
        )
    }
}

export default TopBanner