import React from 'react'
import {
    Section,
    Container,
    Row,
    Grid
} from '../Grid'

class FeaturedProjectSection extends React.Component {
    render() {
        return(
            <Section className={`opt_double-grid-section opt_featured-project ${this.props.projectPage > 0 ? 'opt_project-page-grid' : ''}`}>
                <Container> 
                    <Row>
                        {this.props.children}
                    </Row>
                </Container>
            </Section>
        )
    }
}
export default FeaturedProjectSection