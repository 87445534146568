import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import TopHeading from '../../components/Content/TopHeading'
import {
    Section,
    Container,
    Row,
    Grid
} from'../../components/Grid'
import OnScrollAnimation from  '../../components/Animations/OnScrollAnimation.js'
import {connect} from 'react-redux'
import {fetchAbout} from '../../actions'
import {BASE_URL} from '../../path'
import {Link} from 'react-router-dom'


class WhoWeAre extends React.Component {

    componentDidMount(){
        this.props.fetchAbout()
    }

    renderList(){
        return this.props.about.map((item, index) => {
            if(item.image){
                // the first person links to kyle's page'
                if(index == 0){
                    return (                        
                        <Grid className="oo_item" col="4">  
                            <Link to="/kyledavies">
                                <figure>
                                    <img key={index} src={`${BASE_URL}${item.image}`} alt={item.alt} />
                                </figure>   
                                <div className="oo_overlay"></div>      
                                <div className="oo_info">
                                    <h4 className="oo_name">{item.name}</h4>
                                    <p className="oo_position">{item.position}</p>
                                </div>    
                            </Link>             
                        </Grid>                        
                    )
                }
                return (
                     <Grid className="oo_item" col="4">  
                        <figure>
                            <img key={index} src={`${BASE_URL}${item.image}`} alt={item.alt} />
                        </figure>   
                        <div className="oo_overlay"></div>      
                        <div className="oo_info">
                            <h4 className="oo_name">{item.name}</h4>
                            <p className="oo_position">{item.position}</p>
                        </div>                 
                    </Grid>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderPageContent() {
        return(
            <React.Fragment>

                <OnScrollAnimation>
                    <TopHeading
                        heading="Who We Are"
                        text="At Optimal Online we are passionate about working with our clients and seeing their business grow. From helping you plan and implement a full digital strategy, develop a new website or offer ad hoc design support, Optimal Online will support you on your growth journey."
                        pageHeading="1"
                    />
                </OnScrollAnimation>

                <OnScrollAnimation>
                    <Section className="opt_who-we-are-blocks">
                        <Container>
                            <Row>
                                <Grid col="4">
                                    <div className="opt_content">
                                    <h4 className="themeTurquoise">01</h4>
                                        <p className="white">We love what we do and you will see that in our work. No matter how big or small your budget is we can assist you with your business objectives.</p>
                                    </div>
                                </Grid>
                                <Grid col="4">
                                    <div className="opt_content">
                                    <h4 className="themeTurquoise">02</h4>
                                        <p className="white">We understand all aspects of the digital world and have a collective 28 years' experience within our team.</p>
                                    </div>
                                </Grid>
                                <Grid col="4">
                                    <div className="opt_content">
                                    <h4 className="themeTurquoise">03</h4>
                                        <p className="white">Our clients come first, along with their business. We believe in long-lasting relationships and want to guide you along your digital journey.</p>
                                    </div>
                                </Grid>
                            </Row>
                            <Row>
                                <Grid col="4">
                                    <div className="opt_content">
                                    <h4 className="themeTurquoise">04</h4>
                                        <p className="white">Every project we design and develop, we ensure usability is at the forefront.</p>
                                    </div>
                                </Grid>
                                <Grid col="4">
                                    <div className="opt_content">
                                    <h4 className="themeTurquoise">05</h4>
                                        <p className="white">We have a very strict employment policy: We only hire nice people. That means you will only deal with kind friendly people without egos or attitudes.</p>
                                    </div>
                                </Grid>
                                <Grid col="4">
                                    <div className="opt_content">
                                    <h4 className="themeTurquoise">06</h4>
                                        <p className="white">No matter how big the idea, we will find a digital solution for it.</p>
                                    </div>
                                </Grid>
                            </Row>
                        </Container>
                    </Section>
                </OnScrollAnimation>

                {/* <OnScrollAnimation>
                    <Section className="opt_who-we-are-gallery">
                        <Container> 
                            <Row>
                                <Grid col="8">
                                    <h2 className="themeTurquoise">All About The People</h2>
                                    <p className="white">We are a passionate and dedicated team that believes in building successful brands by utilising integrated digital marketing strategies, eye-catching design, expertly crafted content and bespoke digital platforms.</p>                               
                                </Grid>
                                <Grid col="4"></Grid>
                            </Row>
                            <Row className="opt_who-we-are-row">
                               {this.renderList()}
                            </Row>
                        </Container>
                    </Section>
                </OnScrollAnimation> */}
                
            </React.Fragment>

        )
    }

    render() {
        
        return(
            <React.Fragment>
                <div className="opt_app">
                    <Helmet>
                        <title>Optimal Online | About</title>
                        <meta name="description" content="We specialise in strategic and practical online communication solutions, from brand development to digital marketing, CRM, e-commerce, design, UI/UX and web design. Everything your business needs for a successful digital journey starts here." />
                    </Helmet>
                    <Nav>{this.renderPageContent()}</Nav>
                </div>
            </React.Fragment>
        )
    }
}

// export default WhoWeAre

const mapStateToProps = state => {
    return {
        about: state.aboutState
    }
}

export default connect(mapStateToProps, {
    fetchAbout
})(WhoWeAre)