import React from 'react'
//cant use BrowserRouter with our own history object(which we recreate in order to get access to it for navigation)
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom"
import Home from './Home/Home'
import Projects from './Projects/Projects'
import Project from './Projects/Project'
import ContactUs from './ContactUs/ContactUs'
import WhatWeDo from './WhatWeDo/WhatWeDo'
import WhoWeAre from './WhoWeAre/WhoWeAre'
import ThankYou from './ContactUs/ThankYou'
import WeAreSorry from './ContactUs/WeAreSorry'
import ServicesRedirect from './Redirect/ServicesRedirect'
import PageNotFound from './PageNotFound/PageNotFound'
// import ScrollToTop from '../components/Helpers/ScrollToTop'
import Kyledavies from './ContactUs/Kyledavies'
import history from '../history'
import PrivacyPolicy from './LegalStuff/PrivacyPolicy'

class App extends React.Component {

    render() {
        return (
            <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
                {/* <ScrollToTop />            */}
                <Switch>

                    <Route 
                        exact 
                        path="/"
                        component={Home}
                    />

                    <Route 
                        exact 
                        path="/work"
                        component={Projects}
                    />

                    <Route 
                        exact 
                        path="/work/:searchName"
                        component={Project}
                    />

                    <Route 
                        exact 
                        path="/services"
                        component={WhatWeDo}
                    />

                    <Route 
                        exact 
                        path="/services/:slug"
                        component={ServicesRedirect}
                    />

                    <Route 
                        exact 
                        path="/about"
                        component={WhoWeAre}
                    />

                    <Route 
                        exact 
                        path="/contact"
                        component={ContactUs}
                    />

                    <Route 
                        exact 
                        path="/thank-you"
                        component={ThankYou}
                    />

                    <Route 
                        exact 
                        path="/we-are-sorry"
                        component={WeAreSorry}
                    />

                    <Route 
                        exact 
                        path="/kyledavies"
                        component={Kyledavies}
                    />
                    
                    <Route 
                        exact 
                        path="/privacy-policy"
                        component={PrivacyPolicy}
                    />           

                    <Route component={PageNotFound} />

                </Switch>
            </Router>
        )
    }
}

export default App;