import React from 'react'
import {Link} from 'react-router-dom'
import {
    Grid
} from '../Grid'

class FeaturedProjectGrid extends React.Component {
    
    render() {
        const style = {
            backgroundImage: `url(${this.props.bgImg})`
        }
        return(
            <Grid col="6" className="opt_featured-project-grid">
                <Link className="opt_featured-project-hover" to={this.props.buttonLink ? this.props.buttonLink : null}>
                    <div className="opt_featured-project-img" style={style}>
                        <div className="opt_featured-project-content">
                            <h2 className="white" style={{
                                color: this.props.textColor
                            }}>{this.props.heading}</h2>
                            <h3 className="white" style={{
                                color: this.props.textColor
                            }} >{this.props.subHeading}</h3>
                            <Link className="themeTurquoise" to={`${this.props.buttonLink ? this.props.buttonLink : null}`} style={{
                                color: this.props.textColor
                            }}>View Project</Link>
                        </div> 
                    </div>
                </Link>
            </Grid>
        )
    }
}
export default FeaturedProjectGrid