import React from 'react'
import {
    Section,
    Container,
    Row,
    Grid
} from '../Grid'
import BannerText from './BannerText'

class DoubleGridSection extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            servicesId: this.props.servicesId ? this.props.servicesId : null,
        }
    }
    render() {
        
        return(
            <div id={this.props.servicesId ? this.props.servicesId : ''}>
                <Section className={`opt_double-grid-section ${this.props.order > 0 ? 'opt_swopped' : ''} ${this.props.projectPage > 0 ? 'opt_project-page-grid' : ''} ${this.props.className ? this.props.className : ''}`}>
                    <Container>
                        <Row>
                            <Grid col="6">
                                <figure>
                                    <img src={this.props.img} alt={this.props.alt} />
                                </figure>
                            </Grid>
                            <Grid col="6">
                                <BannerText props={this.props} />
                            </Grid>
                        </Row>
                    </Container>
                </Section>
            </div>
        )
    }
}
export default DoubleGridSection