import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import TopHeading from '../../components/Content/TopHeading'
import FeaturedProjectSection from '../../components/Content/FeaturedProjectSection'
import FeaturedProjectGrid from '../../components/Content/FeaturedProjectGrid'
import OnScrollAnimation from  '../../components/Animations/OnScrollAnimation.js'
import {connect} from 'react-redux'
import {fetchProjects} from '../../actions'
import {BASE_URL} from '../../path'

class Projects extends React.Component {

    componentDidMount(){
        this.props.fetchProjects()
    }

    renderList(){
        return this.props.projects.map((item, index) => {
            if(item.title){
                return (
                    <FeaturedProjectGrid 
                        key={index}
                        bgImg={`${BASE_URL}${item.featured_img}`}
                        heading={item.title}
                        subHeading={`${item.main_service_heading === null ? '' : item.main_service_heading} ${item.service_heading && item.main_service_heading ? ' | ' : ''} ${item.service_heading === null ? '' : item.service_heading}`}
                        buttonLink={`/work/${item.search_name}`}
                    />
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    

    render() {
        
        return(
            <React.Fragment>
                <Helmet>
                    <title>Optimal Online | Projects</title>
                    <meta name="description" content="At Optimal Online we are passionate about working with our clients and seeing their business grow. From helping you plan and implement a full digital strategy, develop a new website or offer ad hoc design support, Optimal Online will support you on your growth journey." />
                </Helmet>
                <Nav>
                     <OnScrollAnimation>
                        <TopHeading
                            subHeading="See the results"
                            heading="Featured Projects"
                            text="At Optimal Online we are passionate about working with our clients and seeing their business grow. From helping you plan and implement a full digital strategy, develop a new website or offer ad hoc design support, Optimal Online will support you on your growth journey."
                            pageHeading="1"
                        />
                    </OnScrollAnimation>

                    <OnScrollAnimation>
                        <FeaturedProjectSection>
                            {this.renderList()}
                        </FeaturedProjectSection>
                    </OnScrollAnimation>
                </Nav>
            </React.Fragment>
        )
    }
}

// export default Projects

const mapStateToProps = state => {
    return {
        projects: state.projectsState
    }
}

export default connect(mapStateToProps, {
    fetchProjects
})(Projects)