import React from 'react'
import ReactDOM from 'react-dom'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    acceptCookie
} from '../actions'

class Cookie extends React.Component {

    onClick = () => {
        this.props.acceptCookie()
    }

    render() {

        if(this.props.accept){return <></>}

        return(
            ReactDOM.createPortal(
                <div 
                    className='_cookie'
                    style={{
                    padding: '20px 30px',
                    display: 'flex',
                    justifyContent: 'center',
                    columnGap: '30px',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    rowGap: '10px',
                    textAlign: 'center'
                }}>
                    <p style={{
                        margin: '0px',
                        width: '100%',
                        maxWidth: '700px'
                    }}>On www.optimalonline.co.za we use Cookies. By using this site, you are accepting our <strong><Link to="/privacy-policy">Cookie Policy.</Link></strong></p>
                    <button className="_button" onClick={this.onClick}>Got It!</button>
                </div>,
                document.querySelector("#cookie")
            )            
        )      
    }
} 

const mapStateToProps = state => {
    return{
        accept: state.cookieState.accept
    }
}

export default connect(mapStateToProps, {
    acceptCookie
})(Cookie)