import React from 'react'
import BannerText from './BannerText'
import {
    Section,
    Container,
    Row,
    Grid
} from '../Grid'
import bgImg from '../../assets/img/all-about-the-people-02@2x.png'

class AboutPeopleSection extends React.Component {
    render() {
        const style = {
            backgroundImage: `url(${bgImg})`
        }
        return(
            <Section className="opt_double-grid-section opt_about-people-section" >
                <Container>
                    <Row>
                        <Grid col="6">
                            <div className="opt_bg-img opt_all-about-the-people-img" style={style}></div>
                        </Grid>
                        <Grid col="6">
                            <BannerText props={this.props} />
                        </Grid>
                    </Row>
                </Container>
            </Section>
        )
    }
}
export default AboutPeopleSection