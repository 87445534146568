import React from 'react'
import {
    Section,
    Container,
    Row,
    Grid
} from '../Grid'

class TopHeading extends React.Component {
    render(){
        return(
            <Section className={`${this.props.pageHeading > 0 ? 'opt_page-heading' : 'opt_top-heading'} ${this.props.className ? this.props.className : '' }`}>
                <Container>
                    <Row>
                        <Grid col="8">
                            <h2 className="themeTurquoise">{this.props.heading}</h2>
                            {
                                this.props.text && <p className="white">{this.props.text}</p>
                            }     
                            {
                                this.props.children && (
                                    <div className="white">{this.props.children}</div>
                                )
                            }                      
                        </Grid>
                        <Grid col="4">   
                            {
                                this.props.image && <img src={this.props.image} alt="" />
                            }                           
                        </Grid>
                    </Row>
                </Container>
            </Section>
        )
    }
}

export default TopHeading;