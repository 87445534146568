import React from 'react'
import {Link} from 'react-router-dom'

class NavLink extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			href: this.props.href
		}
	}
	render(){
		return(
			<li className="opt_list-item_big">
                <Link to={this.props.href}>{this.props.text}</Link>
            </li>
		)
	}
}

export default NavLink