import React from 'react'
import { Link } from 'react-router-dom'

class Button extends React.Component {
	render(){
		return (
			<button 
				className={`opt_button_turquoise ${this.props.className ? this.props.className : ''}`} 
			> 	
                <Link to={this.props.url ? this.props.url : '/'}>
                    {this.props.text}
                </Link>
			</button>
		)
	}
}

export default Button