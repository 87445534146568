import {
    FETCH_PROJECTS_MORE
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_PROJECTS_MORE:
            return {
                ...state,
                [action.searchName]: action.payload
            }
        default:
            return state
    }
}