import React from 'react'
import {Link} from 'react-router-dom'
import GetInTouch from '../../views/Forms/GetInTouch'
import Clearfix from '../Helpers/Clearfix'
import facebookIcon from '../../assets/img/facebook-icon.svg'
import instagramIcon from '../../assets/img/instagram-icon.svg'
import linkedInIcon from '../../assets/img/linkedin-icon.svg'
import upButtonIcon from '../../assets/img/up-button.svg'
import Cookie from '../Cookie'

class Footer extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="footer_top">
                    <GetInTouch />
                </div>
                <footer>
                    <div className="item opt_display-mobile">
                        <h4 className="themeTurquoise">Get In Touch</h4>
                        <div className="opt_border-bottom"></div>
                        {/*<Link to="/">Terms &amp; Conditions</Link> | <Link to="/">Privacy Policy</Link>*/}
                    </div>
                    <div className="item">
                        <div className="opt_contact-item"><span className="themeTurquoise">Email</span>
                        <Clearfix />
                        <a href="mailto:kyle@optimalonline.co.za">kyle@optimalonline.co.za</a></div>
                        <Clearfix />
                        <div className="opt_contact-item"><span className="themeTurquoise">Phone Number</span>
                        <Clearfix />
                        <a href="tel:+27834261066">+27 (0) 83 426 1066</a></div>
                        <Clearfix />
                        <div className="opt_contact-item"><span className="themeTurquoise">Address</span>
                        <Clearfix />
                        144 Main Road, Somerset West, 7130</div>
                       
                    </div>
                    <div className="item opt_social-mobile">
                        <div className="opt_social-item opt_display-mobile"><span className="themeTurquoise">Social Media</span></div>
                        <Clearfix />
                        <a className="opt_socmed-1" href="https://www.facebook.com/optimalonlineptyltd/" target="_blank"><img src={facebookIcon} alt="Optimal Online - Facebook" width="45" height="45" /></a>
                        <a href="https://www.instagram.com/optimalonline/" target="_blank"><img src={instagramIcon} alt="Optimal Online - Instagram" width="45" height="45" /></a>
                        <a href="https://www.linkedin.com/company/optimal-online-pty-ltd" target="_blank"><img src={linkedInIcon} alt="Optimal Online -  LinkedIn" width="45" height="45" /></a>
                    </div>
                    <div>
                         <a className="opt_up-button" href="#top_of_page"><img src={upButtonIcon} alt="" width="45" height="45" /></a>
                    </div>
                </footer>
                <Cookie />
            </React.Fragment>
        )
    }
}

export default Footer