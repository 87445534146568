import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import TopHeading from '../../components/Content/TopHeading'
import {
    Section,
    Container,
    Row,
    Grid
} from'../../components/Grid'
import DoubleGridSection from '../../components/Content/DoubleGridSection'
import OnScrollAnimation from  '../../components/Animations/OnScrollAnimation.js'

//images
import landingImg from '../../assets/img/Online-Brand-Development@2x.png'
import landingImg2 from '../../assets/img/Web-Development@2x.png'
import landingImg3 from '../../assets/img/Email-Marketing@2x.png'
import landingImg4 from '../../assets/img/Digital-Design@2x.png'
import landingImg5 from '../../assets/img/Ecommerce@2x.png'
import landingImg6 from '../../assets/img/UI_UX@2x.png'
import webSevicesIcon from '../../assets/img/discuss-a-project-button.svg'
import promotional from '../../assets/img/Promotional-Products-Image.png'

//social media icons
import icon1 from '../../assets/img/social-media-icons/Icon-01@2x.png'
import icon2 from '../../assets/img/social-media-icons/Icon-02@2x.png'
import icon3 from '../../assets/img/social-media-icons/Icon-03@2x.png'
import icon4 from '../../assets/img/social-media-icons/Icon-04@2x.png'
import icon5 from '../../assets/img/social-media-icons/Icon-05@2x.png'
import icon6 from '../../assets/img/social-media-icons/Icon-06@2x.png'
import icon7 from '../../assets/img/social-media-icons/Artboard–5@2x.png'
import icon8 from '../../assets/img/social-media-icons/Icon-07@2x.png'
import icon9 from '../../assets/img/social-media-icons/Icon-08@2x.png'

import icon10 from '../../assets/img/social-media-icons-02@2x.png'
import icon11 from '../../assets/img/social-media-icons-03@2x.png'

//paid search icons
import psicon1 from '../../assets/img/paid-search/bolster-awareness-icon.svg'
import psicon2 from '../../assets/img/paid-search/increase-revenue-icon.svg'
import psicon3 from '../../assets/img/paid-search/pinpoint-customers-icon.svg'
import psicon4 from '../../assets/img/paid-search/continued-flexibility-icon.svg'

//web services icons
import wsicon1 from '../../assets/img/web-services/web-services-icon.svg'
import wsicon2 from '../../assets/img/web-services/maintenance-icon.svg'
import wsicon3 from '../../assets/img/web-services/optimisation-icon.svg'

class WhatWeDo extends React.Component {
    renderPageContent() {
        const style = {
          paddingTop: "20px"
        };
        return(
            <React.Fragment>

                <OnScrollAnimation>
                    <TopHeading
                        subHeading="Services designed for you"
                        heading="What We Do"
                        text="We specialise in strategic and practical online communication solutions, from brand development to digital marketing, CRM, e-commerce, design, UI/UX and web design. Everything your business needs for a successful digital journey starts here."
                        pageHeading="1"
                    />
                </OnScrollAnimation>

                <OnScrollAnimation>
                    <DoubleGridSection
                        img={landingImg}
                        heading="Online Brand Development"
                        text="<p>A business's brand is one of its most valuable assets. A brand incorporates a business’s values, vision, personality and promise. It is the perception that clients hold in their minds. The Internet and its related technology offer incredible opportunities for businesses to engage with current and potential clients in new ways and vice versa.</p>"
                        order="1"
                        servicesId="online-brand-development"
                        className="opt_online-brand-dev"
                    />    
                </OnScrollAnimation>

                <OnScrollAnimation>
                    <DoubleGridSection
                        img={landingImg2}
                        heading="Web Development"
                        text="<p>A website is essentially your digital office. It is where you meet your clients online. It is where you convince customers as to why your amazing product or service is right for them. It is ultimately where you turn interest into sales. Web development is not simply building a website. It is a comprehensive strategy. Where do your clients come from, what do they first see when they land on your page? We do not buy off-the-shelf website templates but instead build your website from scratch – so that every single element is taken into consideration.</p>"
                        order="0"
                        servicesId="web-development"
                        className="opt_web-dev-section"
                    />    
                </OnScrollAnimation>

                

                <OnScrollAnimation>
                    <Section className="opt_what-we-do-blocks" id="web-services">
                        <Container>
                            <Row>
                                <Grid col="12">
                                    <h2 className="themeTurquoise" style={{
                                        'textAlign': 'center'
                                    }}>Web Services</h2>
                                </Grid>
                                <Grid col="4">
                                    <div className="opt_content">
                                        <figure>
                                            <img src={wsicon1} alt="" />
                                        </figure>
                                        <h4 className="themeTurquoise">Web Services</h4>
                                        <p className="white">We will host your site on a fully monitored and protected AWS server. Speed, security and reliability are guaranteed.</p>
                                    </div>
                                </Grid>
                                <Grid col="4">
                                    <div className="opt_content">
                                        <figure>
                                            <img src={wsicon2} alt="" />
                                        </figure>
                                        <h4 className="themeTurquoise">Maintenance</h4>
                                        <p className="white">Publishing your site is only the beginning. We also offer structured support contracts to give you peace of mind that your site is always up-to-date and functioning in the best possible way.</p>
                                    </div>
                                </Grid>
                                <Grid col="4">
                                    <div className="opt_content">
                                        <figure>
                                            <img src={wsicon3} alt="" />
                                        </figure>
                                        <h4 className="themeTurquoise">Optimisation</h4>
                                        <p className="white">Building a responsive, eye-catching website isn’t enough to get found. If you want to ensure growth, your site needs to be seen by the right people. That’s where our search engine optimisation services come in.</p>
                                    </div>
                                </Grid>
                            </Row>
                        </Container>
                    </Section>
                </OnScrollAnimation>

                <OnScrollAnimation>
                    <DoubleGridSection
                        img={landingImg3}
                        heading="Email Marketing"
                        text="<p>Email marketing has been around forever, and for good reason. It’s the most direct and effective way of connecting with your current clients, members, and leads, nurturing them and turning them into customers, consistently winning out over all other marketing channels."
                        order="1"
                        servicesId="email-marketing"
                        className="opt_email-marketing"
                    />    
                </OnScrollAnimation>

                <OnScrollAnimation>
                    <div id="social-media" style={style}>
                        <Section className="opt_what-we-do-center opt_social-media-section">
                            <Container className="opt_small">
                                <Row>
                                    <figure className="opt_wds">
                                        <img src={icon1} alt="" style={{width:'40px'}} />
                                    </figure>
                                    <figure className="opt_wds">
                                        <img src={icon2} alt="" style={{width:'100px'}} />
                                    </figure>
                                    <figure className="opt_wds">
                                        <img src={icon3} alt="" style={{width:'40px'}} />
                                    </figure>
                                    <figure className="opt_wds">
                                        <img src={icon4} alt="" style={{width:'100px'}} />
                                    </figure>
                                    <figure className="opt_mobile opti_sm-icon-mobile">
                                        <img src={icon10} alt="" />
                                    </figure>                                    
                                    <Grid col="12">
                                        <h2 className="themeTurquoise">Social Media</h2>
                                        <p className="white">Social media plays a crucial role in how people interact with each other and your brand. From a business perspective, it contributes to how companies interact and engage with potential customers. Social media has become a powerful method for businesses of all sizes to reach a target market, many social platforms have integrated methods to help businesses reach their audiences.</p>
                                    </Grid>
                                    <figure className="opt_mobile opti_sm-icon-mobile">
                                        <img src={icon11} alt="" />
                                    </figure>
                                    <figure className="opt_wds">
                                        <img src={icon5} alt="" style={{width:'100px'}} />
                                    </figure>
                                    <figure className="opt_wds">
                                        <img src={icon6} alt="" style={{width:'40px'}} />
                                    </figure>
                                    <figure className="opt_wds">
                                        <img src={icon7} alt="" style={{width:'100px'}} />
                                    </figure>
                                    <figure className="opt_wds">
                                        <img src={icon8} alt="" style={{width:'40px'}} />
                                    </figure>
                                    <figure className="opt_wds opt_twitter-icon">
                                        <img src={icon9} alt="" style={{width:'100px'}} />
                                    </figure>
                                </Row>
                            </Container>
                        </Section>
                    </div>
                </OnScrollAnimation>

                <OnScrollAnimation>
                    <DoubleGridSection
                        img={landingImg4}
                        heading="Digital Design"
                        text="<p>Digital Design is what brings your client and your business together. It’s not simply a name or a logo but an overall perception that is reinforced amongst consumers when they encounter your brand online. Digital design and brand go hand in hand. Design has the ability to communicate a great message instantly via an emotional connection, and the brand is a message communicating the importance and essence of your business.<p>"
                        order="0"
                        servicesId="digital-design"
                        className="opt_digital-des-section"
                    />    
                </OnScrollAnimation>

                 <OnScrollAnimation>
                    <DoubleGridSection
                        img={landingImg5}
                        heading="E-commerce"
                        text="<p>E-commerce marketing is the process of driving sales by raising awareness about an online store’s brand and product offerings. Digital marketing for e-commerce applies traditional marketing principles to a multichannel and data-driven environment. E-commerce marketing can be divided into two general actions, driving website traffic and optimising the user experience for conversion. Both are critical components to growing an online business.</p>"
                        order="1"
                        className="opt_ecommerce-section"
                    />    
                </OnScrollAnimation>

                <OnScrollAnimation>
                    <DoubleGridSection
                        img={landingImg6}
                        heading="UI/UX Design"
                        text="<p>The combination of understanding what experience you would like your client to have and the interface in which they should experience your product or service is a fundamental part of all the digital work we do. Understanding how your audience uses the internet will drive a uniquely tailored digital execution.</p>"
                        order="0"
                        className="opt_ui-ux-section"
                    />    
                </OnScrollAnimation>

                <OnScrollAnimation>
                    <DoubleGridSection
                        img={promotional}
                        heading="Promotional Products"
                        text="<p>With Optimal Online's Promotional Products department, we give you the confidence and ability to bring your brand to life with infinite possibilities. With Partners and suppliers and a network of branches across South Africa, Namibia, Botswana, Zambia, Kenya and Nigeria we are committed to providing state-of-the-art Promotion Products. We offer Branded Apparel, Headware, Workwear, Display advertising, and Packaging that can all be customized accordingly.</p>"
                        order="1"
                        servicesId="promotional-products"
                        className="opt_ui-promotional-section"
                    />    
                </OnScrollAnimation>

                 <OnScrollAnimation>
                    <DoubleGridSection
                        img={landingImg}
                        heading="CRM"
                        text="<p>Customer Relationship Management (CRM) is a term that refers to practices, strategies and technologies that companies use to manage and analyse customer interactions and data throughout the customer lifecycle with the goal of improving business relationships with clients, assisting in retention and driving sales growth.</p>"
                        order="0"
                        className="opt_crm-section"
                    />    
                </OnScrollAnimation>

                <OnScrollAnimation>
                    <Section className="opt_what-we-do-center opt_paid-search-section">
                        <Container className="opt_small">
                            <Row>
                                <Grid col="12">
                                    <h2 className="themeTurquoise">Paid Search</h2>
                                    <h3 className="white">(Performance Driven Paid Media)</h3>
                                    <p className="white">When you analyse effectively, find the right keywords, and target the right audience, PPC marketing delivers massive returns. You can’t let your PPC campaigns just tick along, it is so much bigger than that, and that’s why you need an experienced agency to take your online presence to the next level and ultimately grow your business through a tailored marketing strategy. We’ll carry out initial market research to fully scope your campaign. We will provide in-depth analysis to ensure your ads are displaying for the right keywords and ensure you are getting the right visitors to your website, at the right time.</p>
                                </Grid>
                            </Row>
                        </Container>
                    </Section>
                </OnScrollAnimation>

                <OnScrollAnimation>
                    <Section className="opt_what-we-do-blocks">
                        <Container>
                            <Row>
                                <Grid col="6">
                                    <div className="opt_content">
                                        <figure>
                                            <img src={psicon1} alt="" />
                                        </figure>
                                        <h4 className="themeTurquoise">Bolster Awareness</h4>
                                        <p className="white">Your ad copy and creative needs to cut through the noise to get your ads seen. We’ll make sure your ads are highly relevant to your audience and consistently tested and refined to not only increase your visibility but crucially your profitability.</p>
                                    </div>
                                </Grid>
                                <Grid col="6">
                                    <div className="opt_content">
                                        <figure>
                                            <img src={psicon2} alt="" />
                                        </figure>
                                        <h4 className="themeTurquoise">Increase Revenue</h4>
                                        <p className="white">We will ensure that your paid media strategy quite literally pays off, taking your business to the next level. We’ll drive revenue through targeted PPC campaigns, supporting other channels to drive sales.</p>
                                    </div>
                                </Grid>
                                <Grid col="6">
                                    <div className="opt_content">
                                        <figure>
                                            <img src={psicon3} alt="" />
                                        </figure>
                                        <h4 className="themeTurquoise">Pinpoint Customers</h4>
                                        <p className="white">By finding the right mix of positive and negative keywords that offer high user intent, we aim to reach the right customers at the right time. Whether your website is e-commerce or enquiry-based, the visitors being driven to your website will be highly targeted, quality leads from a trusted traffic source.</p>
                                    </div>
                                </Grid>
                                <Grid col="6">
                                    <div className="opt_content">
                                        <figure>
                                            <img src={psicon4} alt="" />
                                        </figure>
                                        <h4 className="themeTurquoise">Continued Flexibility</h4>
                                        <p className="white">We’ll be constantly refining your campaigns, improving them periodically based on what your data is telling us. Through regular bid management, we’ll make certain there’ll be no overspending on keywords.</p>
                                    </div>
                                </Grid>
                            </Row>
                        </Container>
                    </Section>
                </OnScrollAnimation>
                
            </React.Fragment>

        )
    }

    render() {
        
        return(
            <React.Fragment>
                <div className="opt_app">
                    <Helmet>
                        <title>Optimal Online | Services</title>
                        <meta name="description" content="We specialise in strategic and practical online communication solutions, from brand development to digital marketing, CRM, e-commerce, design, UI/UX and web design. Everything your business needs for a successful digital journey starts here." />
                    </Helmet>
                    <Nav noScrollToTop>{this.renderPageContent()}</Nav>
                </div>
            </React.Fragment>
        )
    }
}

export default WhatWeDo