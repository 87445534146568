import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'

class ContactUs extends React.Component {
    renderPageContent() {
        return(
            <React.Fragment>
                <div className="opt_white-space"></div>
            </React.Fragment>

        )
    }

    render() {
        
        return(
            <React.Fragment>
                <Helmet>
                    <title>Optimal Online | Contact Page</title>
                    <meta name="description" content="We specialise in strategic and practical online communication solutions, from brand development to digital marketing, CRM, e-commerce, design, UI/UX and web design. Everything your business needs for a successful digital journey starts here." />
                </Helmet>
                <Nav>{this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

export default ContactUs