import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import {
    Section,
    Container,
    Row,
    Grid
} from'../../components/Grid'
import Clearfix from '../../components/Helpers/Clearfix'
import Button from '../../components/Buttons/Button'

class WeAreSorry extends React.Component {
    renderPageContent() {
        return(
            <React.Fragment>

                <Section className="opt_thank-you-section">
                    <Container>
                        <Row>
                            <Grid col="12">
                                <div className="opt_thank-you-content">
                                    <h1 className="themeTurquoise">We're Sorry</h1>
                                    <p className="white">Your message couldn't be send. Please try again later <Clearfix />
                                     or email <a className="white" href="mailto:kyle@optimalonline.co.za?subject=Optimal Online - Get In Touch Email">kyle@optimalonline.co.za</a>.</p>
                                    <Button text="Close" />
                                </div>
                            </Grid>
                        </Row>
                    </Container>
                </Section>
                
            </React.Fragment>

        )
    }

    render() {
        
        return(
            <React.Fragment>
                <Helmet>
                    <title>Optimal Online | We're Sorry Page</title>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <Nav>{this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

export default WeAreSorry