import optimal from '../api'
import {
    FETCH_PROJECTS,
    FETCH_PROJECTS_HOME,
    FETCH_PROJECT_DETAILS,
    FETCH_PROJECTS_MORE,
    FETCH_CLIENTS,
    FETCH_ABOUT,
    SEND_CONTACTFORM_EMAIL,
    SEND_CONTACTFORM_EMAIL_ERROR,
    ACCEPT_COOKIE
} from './types'
import history from '../history'

export const acceptCookie = () => {
    return (dispatch) => {
        dispatch({
            type: ACCEPT_COOKIE
        })
    }
}

export const fetchProjects = () => async dispatch => {
    const response = await optimal.get('projects')
    dispatch ({
        type: FETCH_PROJECTS,
        payload: response.data
    })
}

export const fetchProjectsHome = () => async dispatch => {
    const response = await optimal.get('projects/home')
    dispatch ({
        type: FETCH_PROJECTS_HOME,
        payload: response.data
    })
}

export const fetchProjectDetails = searchName => async dispatch => {

    try {

        const response = await optimal.get(`projects/${searchName}`)

        dispatch ({
            type: FETCH_PROJECT_DETAILS,
            payload: response.data,
            searchName
        })
        
    } catch (error) {
        dispatch ({
            type: FETCH_PROJECT_DETAILS,
            payload: error,
            searchName
        })
    }
    
}

export const fetchProjectsMore = searchName => async dispatch => {
    
    try {
        
        const response = await optimal.get(`projects/more/${searchName}`)
        dispatch ({
            type: FETCH_PROJECTS_MORE,
            payload: response.data,
            searchName
        })
        
    } catch (error) {
        
        dispatch ({
            type: FETCH_PROJECTS_MORE,
            payload: error,
            searchName
        })

    }
}

export const fetchClients = () => async dispatch => {
    const response = await optimal.get('clients')
    dispatch ({
        type: FETCH_CLIENTS,
        payload: response.data
    })
}

export const fetchAbout = () => async dispatch => {
    const response = await optimal.get('about')
    dispatch ({
        type: FETCH_ABOUT,
        payload: response.data
    })
}

export const contactFormEmail = formValues => async dispatch => {

    try{
        const response = await optimal.post('email/contact', formValues)

        dispatch ({
            type: SEND_CONTACTFORM_EMAIL,
            payload: response.data,
        })

        history.push('/thankyou')
        
    } catch(error){
        dispatch ({
            type: SEND_CONTACTFORM_EMAIL_ERROR,
            payload: error,
        })
    }
}