import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import TopBanner from '../../components/Banner/TopBanner'
import TopHeading from '../../components/Content/TopHeading'
import FeaturedProjectSection from '../../components/Content/FeaturedProjectSection'
import FeaturedProjectGrid from '../../components/Content/FeaturedProjectGrid'
import WhatWeDoSection from '../../components/Content/WhatWeDoSection'
import WhoWeAreSection from '../../components/Content/WhoWeAreSection'
import ClientSection from '../../components/Content/ClientSection'
import AboutPeopleSection from '../../components/Content/AboutPeopleSection'
import landingImg from '../../assets/img/Landing-Page-X2.png'
import OnScrollAnimation from  '../../components/Animations/OnScrollAnimation.js'
import Button from '../../components/Buttons/Button'
import { Grid } from '../../components/Grid'
import {connect} from 'react-redux'
import {fetchProjectsHome} from '../../actions'
import {BASE_URL} from '../../path'

class Home extends React.Component {

    componentDidMount(){
        this.props.fetchProjectsHome()
    }

    renderList(){
        return this.props.projects.map((item, index) => {
            if(item.title){
                return (
                    <FeaturedProjectGrid 
                        key={index}
                        bgImg={`${BASE_URL}${item.featured_img}`}
                        heading={item.title}
                        subHeading={`${item.main_service_heading === null ? '' : item.main_service_heading} ${item.service_heading && item.main_service_heading ? ' | ' : ''} ${item.service_heading === null ? '' : item.service_heading}`}
                        buttonLink={`/work/${item.search_name}`}
                    />
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    render() {
        
        return(
            <React.Fragment>
                <Helmet>
                    <title>Optimal Online | Home</title>
                    <meta name="description" content="At Optimal Online, we are passionate about working with our clients and seeing their businesses grow. From helping you plan and implement a full digital strategy, develop a new website or offer ad hoc design support, Optimal Online will assist you on your growth journey." />
                </Helmet>
                <Nav>
                    <OnScrollAnimation>
                    <TopBanner 
                        img={landingImg}
                        subHeading="Your online presence is"
                        heading="Our Priority"
                        text="All brands and businesses are digital. Some just don’t know it yet. Optimal has identified digitalisation as a competitive driver for brands and businesses to thrive."
                        buttonText="Services"
                        buttonLink="/services"
                    />
                </OnScrollAnimation>

                <OnScrollAnimation>
                    <TopHeading
                        subHeading="See the results"
                        heading="Featured Projects"
                        text="At Optimal Online, we are passionate about working with our clients and seeing their businesses grow. From helping you plan and implement a full digital strategy, develop a new website or offer ad hoc design support, Optimal Online will assist you on your growth journey."
                        className="opt_home-featured-projects"
                    />
                </OnScrollAnimation>

                <OnScrollAnimation>
                    <FeaturedProjectSection>
                        {this.renderList()}
                        <Grid col="12" className="opt_featured-project-button">
                            <Button text="View Projects" url="/work" />
                        </Grid>
                    </FeaturedProjectSection>
                </OnScrollAnimation>
                <OnScrollAnimation>
                    <WhatWeDoSection
                        subHeading="Services designed for you"
                        heading="What We Do"
                        text="<p>We specialise in strategic and practical online communication solutions, from brand development to digital marketing, CRM, e-commerce, design, UI/UX and web design. Everything your business needs for a successful digital journey starts here.</p>"
                        buttonText="View Services"
                        buttonLink="/services"
                    />
                </OnScrollAnimation>
                <OnScrollAnimation>
                    <WhoWeAreSection
                        subHeading="The things that make us"
                        heading="Who We Are"
                        listText1="We love what we do and you will see that in our work. No matter how big or small your budget is we can assist you with your business objectives."
                        listText2="We understand all aspects of the digital world and have a collective 28 years of experience within our team."
                        listText3="Our clients come first, along with their businesses. We believe in long-lasting relationships and want to guide you along your digital journey."
                    />
                </OnScrollAnimation>
                <OnScrollAnimation>
                    <ClientSection />
                </OnScrollAnimation>
                <OnScrollAnimation>
                    <AboutPeopleSection
                        subHeading="Teamwork Makes The Dream Work"
                        heading="All About The People"
                        text="<p>We are a passionate and dedicated team that believes in building successful brands by utilising integrated digital marketing strategies, eye-catching design, expertly crafted content and bespoke digital platforms.</p>"
                        buttonText="Read More"
                        buttonLink="/about"
                    />
                </OnScrollAnimation>
                </Nav>
            </React.Fragment>
        )
    }
}

// export default Home

const mapStateToProps = state => {
    return {
        projects: state.projectsHomeState
    }
}

export default connect(mapStateToProps, {
    fetchProjectsHome
})(Home)