import React from 'react'
import {Link} from 'react-router-dom'
import {
    Section,
    Container,
    Row,
    Grid
} from '../Grid'
import BannerText from './BannerText'
import bgImg from '../../assets/img/Optimal-Icon-02.svg'
import { HashLink } from 'react-router-hash-link';

class WhatWeDoSection extends React.Component {
    render() {
        const style = {
            backgroundImage: `url(${bgImg})`
        }
        return(
            <Section className="opt_double-grid-section opt_what-we-do-section" style={style}>
                <Container>
                    <Row>
                        <Grid col="6">
                            <BannerText props={this.props} />
                        </Grid>
                        <Grid col="6">
                            <ul>

                                <li>
                                    <HashLink to="/services#online-brand-development" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                                        Online Brand Development 
                                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11 11v-11h1v11h11v1h-11v11h-1v-11h-11v-1h11z" class="opt_plus"/></svg>
                                    </HashLink>
                                </li>


                                <li>
                                    <HashLink to="/services#web-development"  scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>Web Development <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11 11v-11h1v11h11v1h-11v11h-1v-11h-11v-1h11z" class="opt_plus"/></svg></HashLink>
                                </li>


                                <li>
                                    <HashLink to="/services#web-services" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>Web Services <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11 11v-11h1v11h11v1h-11v11h-1v-11h-11v-1h11z" class="opt_plus"/></svg></HashLink>
                                </li>


                                <li>
                                    <HashLink to="/services#email-marketing" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>Email Marketing <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11 11v-11h1v11h11v1h-11v11h-1v-11h-11v-1h11z" class="opt_plus"/></svg></HashLink>
                                </li>


                                <li>
                                    <HashLink to="/services#social-media" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>Social Media <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11 11v-11h1v11h11v1h-11v11h-1v-11h-11v-1h11z" class="opt_plus"/></svg></HashLink>
                                </li>


                                <li>
                                    <HashLink to="/services#digital-design" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>Digital Design <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11 11v-11h1v11h11v1h-11v11h-1v-11h-11v-1h11z" class="opt_plus"/></svg></HashLink>
                                </li>


                                <li>
                                    <HashLink to="/services#promotional-products" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>Promotional Products <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11 11v-11h1v11h11v1h-11v11h-1v-11h-11v-1h11z" class="opt_plus"/></svg></HashLink>
                                </li>


                            </ul>
                        </Grid>
                    </Row>
                </Container>
            </Section>
        )
    }
}
export default WhatWeDoSection