import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
//persisting the state after page refresh by saving it into local storage
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

//reducers
import projectsReducer from './projectsReducer'
import projectsHomeReducer from './projectsHomeReducer'
import projectDetailsReducer from './projectDetailsReducer'
import projectsMoreReducer from './projectsMoreReducer'
import clientsReducer from './clientsReducer'
import aboutReducer from './aboutReducer'
import contactFormReducer from './contactFormReducer'
import cookieReducer from './cookieReducer'

const persistConfig = {
    key: 'root',
    storage
}

const rootReducer = combineReducers({
	projectsState: projectsReducer,
	projectsHomeState: projectsHomeReducer,
	projectDetailsState: projectDetailsReducer,
	projectsMoreState: projectsMoreReducer,
    clientsState: clientsReducer,
    aboutState: aboutReducer,
    contactFormState: contactFormReducer,
    cookieState: cookieReducer,
    form: formReducer,
})

export default persistReducer(persistConfig, rootReducer)